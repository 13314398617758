<template>
    <u-sheet-outlined class="outlined-sheet--border"><slot /></u-sheet-outlined>
</template>

<script>
import USheetOutlined from "@/components/publish/styles/sheet/u-sheet-outlined.vue";

export default {
    props: {},
    components: {
        USheetOutlined,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.outlined-sheet {
    padding-top: 30px;
    padding-bottom: 30px;
}
@media (min-width: 768px) {
    .outlined-sheet {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}
</style>
