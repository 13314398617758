<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-secondary itemTitle="입주관심등록" bg="/images/form/registration/visual.jpg">
                <template #title>입주관심등록</template>
            </sub-head-secondary>
        </template>

        <page-section-primary titWrapClass="text-center" class="page-section--first page-section--last">
            <template #tit>입주관심등록</template>

            <div class="page-section__content">
                <form-input :code="APPLICATION_TYPE.REGISTRATION.value" skin="registration" />
            </div>
        </page-section-primary>
    </app-primary>
</template>

<script>
import { APPLICATION_TYPE } from "@/assets/variables";
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadSecondary from "@/sets/parents/pages/sub-head-secondary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import SheetQuarternary from "@/components/publish/parents/sheets/sheet-quarternary.vue";
import TxtPrimary from "@/components/publish/parents/typography/txt-primary.vue";
import FormInput from "@/components/client/form/form-input.vue";

export default {
    components: {
        AppPrimary,
        SubHeadSecondary,
        PageSectionPrimary,
        SheetQuarternary,
        TxtPrimary,
        FormInput,
    },
    data() {
        return {
            APPLICATION_TYPE,
        };
    },
};
</script>

<style lang="scss" scoped></style>
